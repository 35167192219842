<template>
  <div class="userMenu">
    <div class="userMenu__circle" :style="`background-image: url(${$store.getters.getUserData.userProfilePicture})`">

    </div>
    <div class="userMenu__dropdown">
      <p><b>Account Information</b></p>
      <p><b>Username:</b> {{ $store.getters.getUserData.userName }}</p>
      <p><b>Email:</b> {{ $store.getters.getUserData.userEmail }}</p>
      <p><b>Registered:</b> {{ convertDate($store.getters.getUserData.userRegisteredDate) }}</p>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'UserMenu',
  methods: {
    convertDate(time) {
      return moment(time, "YYYY-MM-DD HH:mm:ss").format('LL');
    }
  }
}
</script>

<style scoped>
.userMenu {
  position: relative;
}
.userMenu__circle {
  width: 38px;
  height: 38px;
  border-radius: 50px;
  background-color: var(--fourth__color);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0 0 3px rgb(0 0 0 / 20%);
}
.userMenu__dropdown {
  position: absolute;
  top: 50px;
  right: 0;
  background-color: var(--third__color);
  box-shadow: 0 0 3px rgb(0 0 0 / 15%);
  display: none;
  flex-direction: column;
  font-size: 1.2rem;
  padding: 2rem;
}
.userMenu__dropdown p {
  display: flex;
  margin-bottom: .3rem;
}
.userMenu__dropdown p b {
  margin-right: .5rem;
}
.userMenu__dropdown p:first-child {
  color: var(--primary__color);
  margin-bottom: 10px;
  text-decoration: underline;
}
.userMenu:hover > .userMenu__dropdown {
  display: flex;
}
@media only screen and (max-width: 600px) {
  .userMenu__circle {
    width: 35px;
    height: 35px;
  }
}
</style>

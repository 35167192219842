import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
});

export default createStore({
  state: {
    loading: false,
    isAuth: false,
    token: undefined,
    userData: undefined,
    mobileMenu: false
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    isAunthenticated(state) {
      return state.isAuth
    },
    getToken(state) {
      return state.token
    },
    getUserData(state) {
      return state.userData
    },
    getMobileMenu(state) {
      return state.mobileMenu
    }
  },
  mutations: {
    ADD_LOADING_MUTATION(state) {
      state.loading = true;
    },
    REMOVE_LOADING_MUTATION(state) {
      state.loading = false;
    },
    SET_AUTH_MUTATION(state, condition) {
      state.isAuth = condition;
    },
    SET_TOKEN_MUTATION(state, token) {
      state.token = token;
    },
    SET_USER_DATA_MUTATION(state, userData) {
      state.userData = userData;
    },
    OPEN_MOBILE_MENU_MUTATION(state) {
      state.mobileMenu = true;
    },
    CLOSE_MOBILE_MENU_MUTATION(state) {
      state.mobileMenu = false;
    }
  },
  actions: {
    ADD_LOADING_ACTION({commit}) {
      commit('ADD_LOADING_MUTATION');
    },
    REMOVE_LOADING_ACTION({commit}) {
      commit('REMOVE_LOADING_MUTATION');
    },
    SET_AUTH_ACTION({commit}, condition) {
      commit('SET_AUTH_MUTATION', condition);
    },
    SET_TOKEN_ACTION({commit}, token) {
      commit('SET_TOKEN_MUTATION', token);
    },
    SET_USER_DATA_ACTION({commit}, userData) {
      commit('SET_USER_DATA_MUTATION', userData);
    },
    OPEN_MOBILE_MENU_ACTION({commit}) {
      commit('OPEN_MOBILE_MENU_MUTATION');
    },
    CLOSE_MOBILE_MENU_ACTION({commit}) {
      commit('CLOSE_MOBILE_MENU_MUTATION');
    }
  },
  plugins: [vuexLocal.plugin]
})

<template>
  <SideMenu/>
  <router-view/>
  <Loading v-if="$store.getters.loading" />
</template>

<script>
import SideMenu from '@/components/SideMenu.vue'
import Loading from '@/components/Loading.vue'

export default {
  name: 'App',
  components: {
    SideMenu,
    Loading
  }
}
</script>

<style>
:root {
  /* COLORS */
  --primary__color: #a3b757;
  --secondary__color: #000000;
  --third__color: #ffffff;
  --fourth__color: #7b7c7d;
  --complementary__color: #f1f5f9;
  /* TYPOGRAPHY */
  --fontfamily: 'Montserrat', sans-serif;
  --defaultFontSize: 1.6rem;
  --h1FontSize: 2.2rem;
  --h2FontSize: 1.8rem;
  --h3FontSize: 1.6rem;
  --h4FontSize: 1.6rem;
  --h5FontSize: 1.6rem;
  /* MISC */
  --sideMenuWidth: 260px;
  --overlayBackground: #000000dd;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
html {
  font-size: 62.5%;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--fontfamily);
  font-size: var(--defaultFontSize);
  background-color: var(--complementary__color);
  color: var(--secondary__color);
}
h1 {
  font-size: var(--h1FontSize);
}
h2 {
  font-size: var(--h2FontSize);
}
h3 {
  font-size: var(--h3FontSize);
}
h4 {
  font-size: var(--h4FontSize);
}
h5 {
  font-size: var(--h5FontSize);
}
table {
  border-collapse: collapse;
  width: 100%;
}
th, td {
  text-align: left;
  padding: 10px 20px;
}
th {
  border-top: 1px solid var(--complementary__color);
  border-bottom: 1px solid var(--complementary__color);
}
td {
  color: var(--fourth__color);
}
td:hover {
  background-color: var(--complementary__color);
}
a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
button {
  border: 0;
  border-radius: 50px;
  outline: none;
  padding: 10px;
  background-color: var(--primary__color);
  color: var(--third__color);
  font-weight: bold;
  font-size: 1.4rem;
  cursor: pointer;
  font-family: inherit;
}
input {
  padding: 10px 20px;
  border: 1px solid var(--complementary__color);
  font-family: inherit;
  border-radius: 0px;
  font-size: 1.4rem;
  color: var(--secondary__color);
  margin-bottom: 20px;
}
input::placeholder {
  color: var(--fourth__color);
}
input:focus {
  outline: 1px solid var(--primary__color);
}
input[type=submit] {
  border: 0;
  border-radius: 0;
  outline: none;
  padding: 10px;
  background-color: var(--primary__color);
  color: var(--third__color);
  font-weight: bold;
  font-size: 1.4rem;
  cursor: pointer;
  border-radius: 50px;
}
.content {
  margin-left: var(--sideMenuWidth);
  height: 100%;
  min-height: 100vh;
}
.content__full {
  margin-left: 0px;
}
.content__section {
  padding: 20px 20px;
}
/* PAGINATION PACKAGE */
.pagination-container {
  display: flex;
  column-gap: 10px;
  margin-top: 20px;
  justify-content: center;
  width: 100%;
}
.paginate-buttons {
  height: 35px;
  width: 35px;
  border: 2px solid var(--primary__color);
  padding: 0px;
}
.paginate-buttons:hover {
  background-color: var(--complementary__color);
  color: var(--primary__color);
}
.active-page {
  background-color: var(--third__color);
  border: 2px solid var(--primary__color);
  color: var(--primary__color);
}
/*.active-page:hover {
  background-color: #2988c8;
}*/
/*--------------------------*/
@media only screen and (max-width: 1024px) {
  :root {
    --h1FontSize: 1.8rem;
    --h2FontSize: 1.6rem;
  }
}
@media only screen and (max-width: 768px) {
  :root {
    --sideMenuWidth: 180px;
  }
  a {
    font-size: 1.2rem;
  }
  button {
    font-size: 1.2rem;
  }
  input[type=submit] {
    font-size: 1.2rem;
  }
}
@media only screen and (max-width: 600px) {
  :root {
    --h1FontSize: 1.4rem;
    --h2FontSize: 1.4rem;
    --defaultFontSize: 1.4rem;
    --sideMenuWidth: 55px;
  }
  /* PAGINATION PACKAGE */
  .pagination-container {
    column-gap: 5px;
  }
  .paginate-buttons {
    width: 25px;
    height: 25px;
  }
  /*--------------------------*/
}
</style>

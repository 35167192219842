<template>
  <header class="mainMenu">
		<div class="mainMenu__container mainMenu__container--1">
			<button class="mainMenu__container__mobileMenu" @click="menuRequest"><i :class="menuIcon"></i></button>
			<h1 class="mainMenu__container__title">{{ $route.name }}</h1>
		</div>
		<div class="mainMenu__container mainMenu__container--2">
			<UserMenu v-if="$store.getters.isAunthenticated" />
		</div>
	</header>
</template>

<script>
import UserMenu from '@/components/UserMenu.vue'

export default {
  name: 'MainMenu',
  components: {
	UserMenu
  },
  data() {
	return {
		menuIcon: ''
	}
  },
  methods: {
	menuRequest() {

		if(!this.$store.getters.getMobileMenu) {

			this.menuIcon = 'ri-menu-4-line'
			this.$store.dispatch('OPEN_MOBILE_MENU_ACTION');

		} else {

			this.menuIcon = 'ri-menu-line'
			this.$store.dispatch('CLOSE_MOBILE_MENU_ACTION');

		}
		
	}
  },
	mounted() {

		if(!this.$store.getters.getMobileMenu) {

			this.menuIcon = 'ri-menu-line'

		} else {

			this.menuIcon = 'ri-menu-4-line'

		}

	}
}
</script>

<style scoped>
header {
	position: sticky;
	top: 0;
	left: 0;
	width: 100%;
	box-shadow: 0 0 3px rgb(0 0 0 / 15%);
}
.mainMenu {
	background-color: var(--third__color);
	width: 100%;
	height: 100%;
	min-height: 80px;
	display: flex;
}
.mainMenu__container {
	padding: 20px;
}
.mainMenu__container--1 {
	width: 80%;
	display: flex;
	align-items: center;
}
.mainMenu__container--2 {
	width: 20%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.mainMenu__container__mobileMenu {
	padding: 0px;
	width: 35px;
	height: 35px;
	font-size: 1.6rem;
	margin-right: 10px;
}
@media only screen and (max-width: 600px) {
	.mainMenu__container__mobileMenu {
		width: 30px;
		height: 30px;
		font-size: 1.4rem;
	}
	.mainMenu__container {
		padding: 10px;
	}
}
</style>
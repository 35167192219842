<template>
  <div :class="'login content' + (!$store.getters.getMobileMenu ? ' content__full' : '')">
    <MainMenu />
    <section class="content__section">
      <div class="login__container">
        <div class="login__box">
          <picture class="login__picture">
            <img class="login__logo" src="@/assets/logo-green.png" alt="Logo">
          </picture>
          <!-- <h2 class="login__title">Admin Panel</h2> -->
          <form class="login__form" v-on:submit.prevent="loginRequest">
            <input type="text" placeholder="Username or email address" v-model="username" required>
            <input type="password" placeholder="Password" v-model="password" required>
            <p class="login__error" v-if="errorMsg"> {{ errorMsg }} </p>
            <input type="submit" value="Login">
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import MainMenu from '@/components/MainMenu.vue'
import Wordpress from '@/wordpress/controller'

export default {
  name: 'LoginView',
  components: {
    MainMenu
  },
  data() {
    return {
      username: undefined,
      password: undefined,
      errorMsg: undefined
    }
  },
  methods: {
    loginRequest() {
      
      Wordpress.login(this.username, this.password).catch((error) => {

        this.errorMsg = error.message

      });

    }
  }
}
</script>

<style scoped>
.login__container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0px 0px 0px;
}
.login__box {
  width: 100%;
  max-width: 600px;
  background-color: var(--third__color);
  padding: 50px;
  box-shadow: 0 0 3px rgb(0 0 0 / 10%);
}
.login__picture {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}
.login__logo {
  width: 100%;
  max-width: 300px;
}
.login__title {
  text-align: center;
}
.login__form {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
.login__error {
  color: #ce2305 !important;
  margin-bottom: 20px;
}
@media only screen and (max-width: 768px) {
  .login__box {
    padding: 40px 30px;
  }
  .login__picture {
    margin-bottom: 30px;
  }
  .login__logo {
    max-width: 250px;
  }
}
@media only screen and (max-width: 600px) {
  .login__container {
    padding: 0px;
  }
  .login__box {
    padding: 30px 20px;
  }
  .login__picture {
    margin-bottom: 20px;
  }
  .login__logo {
    max-width: 200px;
  }
}
</style>
<template>
  <div class="loading">
    <img class="loading__image" src="@/assets/spin.svg" alt="Spin">
  </div>
</template>

<script>
export default {
  name: 'Loading'
}
</script>

<style scoped>
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--overlayBackground);
}
.loading__image {
  width: 100%;
  max-width: 150px;
}
@media only screen and (max-width: 500px) {
  .loading__image {
    max-width: 100px;
  }
}
</style>